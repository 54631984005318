import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [];

export const dictionary = {
		"/[[lang=locale]]/(index-page)": [11,[2],[3]],
		"/[[lang=locale]]/(need-unauth)/account-recovery": [22,[2,9],[3]],
		"/[[lang=locale]]/(non-auth)/like-reload": [34,[2],[3]],
		"/[[lang=locale]]/(need-auth)/logout": [16,[2,4],[3]],
		"/[[lang=locale]]/(need-unauth)/reset-password-from-email": [23,[2,9],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-in": [24,[2,9,10],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-up/direct": [25,[2,9],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-up/direct/complete": [26,[2,9],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-up/direct/email-input": [27,[2,9],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-up/direct/set-password": [28,[2,9],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-up/direct/terms": [29,[2,9],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-up/direct/user-name": [30,[2,9],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-up/direct/verify-code": [31,[2,9],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-up/invite": [32,[2,9],[3]],
		"/[[lang=locale]]/(need-unauth)/super-admin": [33,[2,9],[3]],
		"/[[lang=locale]]/(need-auth)/workspace": [17,[2,4,8],[3]],
		"/[[lang=locale]]/(need-auth)/workspace/create": [18,[2,4,8],[3]],
		"/[[lang=locale]]/(need-auth)/workspace/create/company-info": [19,[2,4,8],[3]],
		"/[[lang=locale]]/(need-auth)/workspace/create/complete": [20,[2,4,8],[3]],
		"/[[lang=locale]]/(need-auth)/workspace/create/select-plan": [21,[2,4,8],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]": [12,[2,4,5],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/(withSidebar)/business-agent": [13,[2,4,5,6],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/(withSidebar)/business-agent/admin": [14,[2,4,5,6],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/(withSidebar)/similar-advisory": [15,[2,4,5,7],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';